import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import Step from './Step/Step.tsx'
import Month from './Month/Month.tsx'
import Excel from './Excel/Excel.tsx'
import Members from './Members/Members.tsx'
import Shift from './Shift/Shift.tsx'

const nextMonth = moment().add(1, 'month')
const currentYear = parseInt(moment().format('YYYY'))
let membersRow: string[] = []
let rows: string[][] = []
let shifts: string[] = []

interface Ishift {
  startTime: string;
  endTime: string;
}
const App: React.FC = (): ReactElement => {
  const [username, setUsername] = useState('')
  useHistory()
  useEffect(() => {
    axios.get('/auth/me')
    .then(res => {
      setUsername(res.data.name)
    })
    .catch(() => {
      // history.push('/login')
    })
  }, [])

  const [month, setMonth] = useState(parseInt(nextMonth.format('M')))
  const changeMonth = (event: ChangeEvent<{name?: string; value: number}>) => {
    setMonth(event.target.value)
  }

  const [year, setYear] = useState(currentYear)
  const changeYear = (event: ChangeEvent<{name?: string; value: number}>) => {
    setYear(event.target.value)
  }

  const onFileChnage = (_rows: string[][], members: string[]) => {
    rows = _rows
    membersRow = rows[3]
    setMembers(members)
  }

  const [members, setMembers] = useState([] as string[])
  const [member, setMember] = useState('')
  const selectMember = async (event: ChangeEvent<{name?: string; value: string}>) => {
    const _member = event.target.value
    let memberIdx = _.findIndex(membersRow, elm => elm === _member)
    shifts = _.slice(rows, 5, 36).map(row => row[memberIdx])
    setMember(_member)
  }

  const [isUploading, setIsUploading] = useState(false)
  const [openSnack, setOpenSnack] = React.useState(false);
  const submit = (events: Ishift[]) => {
    setIsUploading(true)
    axios.post('/events', {
      name: member,
      eventName: '勤務',
      events,
      year,
      month
    })
    .then(() => {
      setOpenSnack(true)
      setIsUploading(false)
    })
    .catch((err) => {
      console.error(err)
      setIsUploading(false)
    })
  }
  const closeSnack = () => setOpenSnack(false)

  return (
    <div>
      <h2>{ username } さん</h2>

      <Step number={ 1 }>
        <p>シフトを取り込む月を選択してください。</p>
        <Month year={ year } month={ month } changeYear={ changeYear } changeMonth={ changeMonth }/>
      </Step>

      <Step number={ 2 }>
        <p>シフトのエクセルファイルを選択してください。</p>
        <Excel onChange={ onFileChnage }/>
      </Step>

      <Step number={ 3 }>
        <p>シフトに記載されているあなたの名前を選択してください。</p>
        <Members onChange={ selectMember } members={ members } member={ member } />
      </Step>

      <Step number={ 4 }>
        { isUploading && <LinearProgress /> }
        <Shift onSubmit={ submit } year={ year } month={ month } member={ member } shifts={ shifts } />
      </Step>

      <Snackbar open={openSnack} autoHideDuration={3000} onClose={ closeSnack }>
        <span>登録完了！</span>
      </Snackbar>
    </div>
  )
}

export default App