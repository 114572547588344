import React, { ReactElement } from 'react'
import LaunchIcon from '@material-ui/icons/Launch';
import Button from '@material-ui/core/Button';
import moment from 'moment'
import _ from 'lodash'
import style from './Shift.module.styl'

interface Ishift {
  startTime: string;
  endTime: string;
}
const shiftMarks = {
  '○': { startTime: '09:30', endTime: '18:00' },
  '△': { startTime: '12:00', endTime: '20:30' },
  '◎': { startTime: '13:00', endTime: '21:30' },
  '☆': { startTime: '11:00', endTime: '19:30' },
  '●': { startTime: '08:30', endTime: '17:00' },
  '□': { startTime: '09:30', endTime: '16:00' },
  '■': { startTime: '10:00', endTime: '18:30' },
  '★': { startTime: '12:30', endTime: '21:00' },
  '！': { startTime: '11:30', endTime: '21:00' },
  '◇': { startTime: '10:00', endTime: '18:30' },
  '♯': { startTime: '09:30', endTime: '16:30' },
}

const weekdays = ['日', '月', '火', '水', '木', '金', '土']

interface Props {
  onSubmit: (events: Ishift[]) => void
  member: string
  shifts: string[]
  year: number
  month: number
}

const Shift: React.FC<Props> = ({ onSubmit, shifts, member, year, month }): ReactElement => {
  const submit = () => {
    const events = _(shifts)
    .map((mark, i) => {
      const shift = _.find(shiftMarks, (_shift, _mark) => mark.indexOf(_mark) > -1)
      if (!shift) {
        return null
      }
      return {
        startTime: moment(`${year}-${month}-${i+1} ${shift.startTime}`, 'YYYY-M-D H:m').toISOString(),
        endTime: moment(`${year}-${month}-${i+1} ${shift.endTime}`, 'YYYY-M-D H:m').toISOString(),
      }
    })
    .filter(_.identity)
    .value()
    onSubmit(events)
  }
  const days = shifts.map((mark, i) => {
    if (member) {
      const shift = _.find(shiftMarks, (_shift: Ishift, _mark: string) => {
        return mark.indexOf(_mark) > -1
      })
      if (shift) {
        const weekday = moment(`${year}-${month}-${ i + 1 }`, 'YYYY-M-D').weekday()
        return (
          <div key={ i } className={ style.day }>
            <span className={ style.date }>{i + 1}日 ({weekdays[weekday]})</span>
            <span className={ style.time }>{ shift.startTime } ~ { shift.endTime }</span>
          </div>
        )
      }
    }
  })
  return (
    <div className={ style.flex }>
      <div className={style.left}>
        <p>シフトを確認したらカレンダーに登録します。</p>
        { member && <Button variant="contained" color="primary" onClick={ submit }>カレンダーに登録</Button> }
        <div className={ style.link }>
          <a target="_blank" href="https://calendar.google.com/calendar/u/0/r">
            Googleカレンダー
            <LaunchIcon color="primary" fontSize="small"></LaunchIcon>
          </a>
        </div>
      </div>
      <div className={style.right}>
        { days }
      </div>
    </div>
  )
}

export default Shift
