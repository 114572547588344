import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

const Home: React.FC = (): ReactElement => {
  return (
    <div>
      <p>
        シフト入れとい太郎は、シフトのエクセルファイルからあなたのシフトを読み取ってGoogleカレンダーに勤務予定を登録するアプリです。
      </p>
      <ol>
        <li>Googleアカウントでログイン</li>
        <li>シフトを登録したい年月を選択</li>
        <li>シフトのエクセルファイルをアップロード</li>
        <li>自分の名前を選択</li>
        <li>「カレンダーに登録」ボタンを押す</li>
      </ol>
      ご利用は<Link to="/app">こちら</Link>から
    </div>
  )
}

export default Home
