import React, { ChangeEvent, ReactElement, useState } from 'react'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import xlsx from 'xlsx'
import _ from 'lodash'

interface Props {
  onChange: (rows: string[][], members: string[]) => void
}
const Excel: React.FC<Props> = ({ onChange }): ReactElement => {
  const [isUploading, setIsUploading] = useState(false)
  async function readXlsx(file: File) {
    const reader = new FileReader()
    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (!e || !e.target) {
        throw new Error('file is null')
      }
      const data = e.target.result as string
      const wb = xlsx.read(data, {type: 'binary'})

      const csv = xlsx.utils.sheet_to_csv(wb.Sheets['ローテーション表(12名+7名用)'])
      const rows = csv.split('\n').map(row => row.split(','))
      const membersRow = rows[3]
      const lastMemberIndex = _.findIndex(membersRow, member => member === '理念手帳') - 1

      const members = _(membersRow).slice(0, lastMemberIndex + 1).filter(_.identity).uniq().value()
      onChange(rows, members)
    }
    reader.onerror = function (error) {
      console.error(error)
    }
    reader.readAsBinaryString(file)
  }

  function onFileChange (event: ChangeEvent<HTMLInputElement>) {
    if (!event.target || !event.target.files|| event.target.files.length < 1) {
      throw new Error('file is not set')
    }
    const file = event.target.files[0]
    setIsUploading(true)
    try {
      readXlsx(file)
    } catch (error) {
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <FormControl>
      <Button variant="contained" color="primary" component="label" disabled={ isUploading }>
        ファイルを選択
        <input type="file" style={ { display: 'none' } } onChange={ onFileChange }></input>
      </Button>
    </FormControl>
  )
}

export default Excel
