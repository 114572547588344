import React, { ReactElement } from 'react'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment'
import _ from 'lodash'

const currentYear = parseInt(moment().format('YYYY'))

interface Props {
  year: number
  month: number
  changeYear: (event: React.ChangeEvent<{name?: string; value: number}>) => void
  changeMonth: (event: React.ChangeEvent<{name?: string; value: number}>) => void
}

const Month: React.FC<Props> = ({ year, month, changeMonth, changeYear }): ReactElement => {
  const years = [currentYear, currentYear + 1].map(i => <MenuItem value={ i } key={ i }>{ i }</MenuItem>)
  const months = _.range(12).map(i => <MenuItem value={ i + 1 } key={ i }>{ i + 1 }</MenuItem>)
  return (
    <>
      <FormControl>
        <InputLabel id="year">年</InputLabel>
        <Select labelId="year" value={ year } onChange={ changeYear }>
          { years }
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="month">月</InputLabel>
        <Select labelId="month" value={ month } onChange={ changeMonth }>
          { months }
        </Select>
      </FormControl>
    </>
  )
}

export default Month
