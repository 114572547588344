// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._23-tAcRCCEdJyJ8wyQXCXk{border:solid 3px #348ceb;margin:30px auto}._23-tAcRCCEdJyJ8wyQXCXk > ._3DVd48-llW0FMjzry5c8MG{background-color:#348ceb;padding:20px;margin:0;color:#fff}._23-tAcRCCEdJyJ8wyQXCXk > ._3WJsdCkVb3rSdOG29zVpN{padding:20px}", "",{"version":3,"sources":["webpack://./src/components/App/Step/Step.module.styl"],"names":[],"mappings":"AAIA,yBACE,wBAAO,CACP,gBAAO,CACP,oDACE,wBAAiB,CACjB,YAAQ,CACR,QAAO,CACP,UAAM,CACR,mDACE,YAAQ","sourcesContent":["primary-color = #348ceb\nwhite-color = #fff\nstep-padding = 20px\n\n.step\n  border solid 3px primary-color\n  margin 30px auto\n  > .heading\n    background-color primary-color\n    padding step-padding\n    margin 0\n    color white-color\n  > .body\n    padding step-padding"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": "_23-tAcRCCEdJyJ8wyQXCXk",
	"heading": "_3DVd48-llW0FMjzry5c8MG",
	"body": "_3WJsdCkVb3rSdOG29zVpN"
};
export default ___CSS_LOADER_EXPORT___;
