import React, { ChangeEvent, ReactElement } from 'react'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import style from './Members.styl'

interface Props {
  onChange: (event: ChangeEvent<{name?: string; value: string}>) => void
  members: string[]
  member: string
}

const Members: React.FC<Props> = ({ onChange, members, member }): ReactElement => (
  <FormControl>
    <InputLabel id="members">君の名は？</InputLabel>
    <Select className={ style.select } labelId="members" value={ member } onChange={ onChange }>
      { members.map(member => <MenuItem value={ member } key={ member }>{ member }</MenuItem>)}
    </Select>
  </FormControl>
)

export default Members