import React, { ReactElement } from 'react'

const Privacy: React.FC = (): ReactElement => {
  return (
    <div>
      <h2>プライバシーポリシー</h2>
      <p>
        シフト取り込んどい太郎（以下、「本サービス」といいます。）は、本ウェブサイト上で提供するサービスにおける個人情報の取り扱いについて、以下の通りプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
      </p>
      <h3>
        個人情報の管理
      </h3>
      <p>
        本サービスは、利用者の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。
      </p>
      <h3>
        個人情報の収集・利用目的
      </h3>
      <p>
        本サービスが個人情報を収集・利用する目的は以下の通りです。<br />
        （１） 本サービスが必要に応じて利用者に連絡をするため、氏名やメールアドレス等の連絡先を利用する目的<br />
        （２） 本サービスが利用者にお知らせや業務のご案内をするため、氏名やメールアドレス、電話番号等の連絡先を利用する目的<br />
        （３） 利用者からの問い合わせに対応するために、本サービスが利用者に対してサービスを提供するにあたって必要となる情報や、利用者のサービス利用状況、連絡先情報等を利用する目的<br />
        （４） 上記の利用目的に付随する目的
      </p>
      <h3>
        個人情報の第三者への開示・提供の禁止
      </h3>
      <p>
        １．本サービスは、次に掲げる場合を除いて、あらかじめ利用者の同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。<br />
        （１） 法令に基づく場合<br />
        （２） 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
        （３） 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
        （４） 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
        （５） あらかじめ次の事項を告知あるいは公表をしている場合<br />
        i. 利用目的に第三者への提供を含むこと<br />
        ii. 第三者に提供されるデータの項目<br />
        iii. 第三者への提供の手段または方法<br />
        iv. 本人の求めに応じて個人情報の第三者への提供を停止すること<br />
        ２．前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。<br />
        （１） 本サービスが利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託する場合<br />
        （２） 合併その他の事由による事業の継承に伴って個人情報が提供される場合<br />
        （３） 個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知りえる状態に置いているとき
      </p>
      <h3>
        個人情報の開示
      </h3>
      <p>
        １．本サービスは、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。<br />
        （１）本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
        （２）本サービスの業務の適正な実施に著しい支障を及ぼすおそれがある場合<br />
        （３）その他法令に違反することとなる場合<br />
        ２．前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
      </p>
      <h3>
        個人情報の訂正および削除
      </h3>
      <p>
        １． 利用者は、本サービスの保有する自己の個人情報が誤った情報である場合には、本サービスが定める手続きにより、本サービスに対して個人情報の訂正または削除を請求することができます。<br />
        ２． 本サービスは、利用者から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これを利用者に通知します。
      </p>
      <h3>
        個人情報の利用停止等
      </h3>
      <p>
        本サービスは、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置を取れる場合は、その代替策を講じます。
      </p>
      <h3>
        プライバシーポリシーの変更
      </h3>
      <p>
        １．本ポリシーの内容は、利用者に通知することなく、変更することができるものとします。<br />
        ２．本サービスが別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときに効力を生じるものとします。
      </p>
      <h3>
        お問い合わせ
      </h3>
      <p>
        本サービスの個人情報の取り扱いに関するお問い合わせは下記までご連絡ください。<br />
        吉岡周<br />
        メールアドレス：yoshioka63@gmail.com
      </p>
    </div>
  )
}

export default Privacy
