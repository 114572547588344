// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HsEVHRCgIO83wvOMjRINP{display:flex}.HsEVHRCgIO83wvOMjRINP > ._1-lAKkh82HiMYg9aC4UPH0{width:50%}.HsEVHRCgIO83wvOMjRINP > ._1-lAKkh82HiMYg9aC4UPH0 > ._3SmnxbNJ7VI4fSTkzkonP5{margin-top:20px}.HsEVHRCgIO83wvOMjRINP > ._31QUwDNFsJDjkc9QfxjA1L{width:50%}.HsEVHRCgIO83wvOMjRINP > ._31QUwDNFsJDjkc9QfxjA1L > ._1rEBIZtTUKO8vRdP_LiWX-{margin-top:10px}.HsEVHRCgIO83wvOMjRINP > ._31QUwDNFsJDjkc9QfxjA1L > ._1rEBIZtTUKO8vRdP_LiWX- > ._17T91mpCIyoZ9NmXoVkkls{display:inline-block;margin-right:5px;width:70px}.HsEVHRCgIO83wvOMjRINP > ._31QUwDNFsJDjkc9QfxjA1L > ._1rEBIZtTUKO8vRdP_LiWX- > ._2f0mJYmMAAHnsXDxhhd1j3{display:inline-block}", "",{"version":3,"sources":["webpack://./src/components/App/Shift/Shift.module.styl"],"names":[],"mappings":"AAAA,uBACE,YAAQ,CACR,kDACE,SAAM,CACN,6EACE,eAAW,CACf,kDACE,SAAM,CACN,6EACE,eAAW,CACX,wGACE,oBAAQ,CACR,gBAAc,CACd,UAAM,CACR,wGACE,oBAAQ","sourcesContent":[".flex\n  display flex\n  > .left\n    width 50%\n    > .link\n      margin-top 20px\n  > .right\n    width 50%\n    > .day\n      margin-top 10px\n      > .date\n        display inline-block\n        margin-right: 5px\n        width 70px\n      > .time\n        display inline-block\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": "HsEVHRCgIO83wvOMjRINP",
	"left": "_1-lAKkh82HiMYg9aC4UPH0",
	"link": "_3SmnxbNJ7VI4fSTkzkonP5",
	"right": "_31QUwDNFsJDjkc9QfxjA1L",
	"day": "_1rEBIZtTUKO8vRdP_LiWX-",
	"date": "_17T91mpCIyoZ9NmXoVkkls",
	"time": "_2f0mJYmMAAHnsXDxhhd1j3"
};
export default ___CSS_LOADER_EXPORT___;
