import React, { ReactElement } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Home from './Home.tsx'
import App from './App/App.tsx'
import Login from './Login.tsx'
import Privacy from './Privacy.tsx'
import style from './Index.module.styl'

const Index: React.FC = (): ReactElement => {
  return (
    <Router>
      <CssBaseline />
      <Container maxWidth="md" className={ style.appContainer }>
        <h1>シフト入れとい太郎</h1>
        <Switch>
          <Route exact path="/" component={ Home }></Route>
          <Route path="/login" component={ Login }></Route>
          <Route path="/app" component={ App }></Route>
          <Route path="/privacy" component={ Privacy }></Route>
        </Switch>
        <footer className={ style.footer }>
          <Link to="/privacy">プライバシーポリシー</Link>
        </footer>
      </Container>
    </Router>
  )
}

export default Index