import React from 'react'
import { render } from 'react-dom'
import "regenerator-runtime/runtime.js"
import Index from './components/Index.tsx'
import axios from 'axios'
import 'normalize.css';
import cookie from 'js-cookie'

axios.defaults.baseURL = 'https://api.shimamuraft.net'
const urlParams = new URLSearchParams(window.location.search);
const token = cookie.get('jwt') || urlParams.get('token')
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  cookie.set('token', token)
}
render(<Index />, window.document.getElementById('root'))