// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1X2SlMBHaoPbi9-5EaTpgO{position:relative;min-height:90vh;padding-bottom:20px}._1X2SlMBHaoPbi9-5EaTpgO > ._258OasTbKQxJCnaCNHxduS{position:absolute;bottom:0}", "",{"version":3,"sources":["webpack://./src/components/Index.module.styl"],"names":[],"mappings":"AAAA,yBACE,iBAAS,CACT,eAAW,CACX,mBAAe,CACf,oDACE,iBAAS,CACT,QAAO","sourcesContent":[".appContainer\n  position relative\n  min-height 90vh\n  padding-bottom 20px\n  > .footer\n    position absolute\n    bottom 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": "_1X2SlMBHaoPbi9-5EaTpgO",
	"footer": "_258OasTbKQxJCnaCNHxduS"
};
export default ___CSS_LOADER_EXPORT___;
