import React, { ReactElement } from 'react'
import style from './Step.module.styl'

interface Props {
  number: number
}
const Step: React.FC<Props> = ({number, children}): ReactElement => (
  <div className={ style.step }>
    <h3 className={ style.heading }>STEP { number }</h3>
    <div className={ style.body }>
      { children }
    </div>
  </div>
)

export default Step