import React, { ReactElement } from 'react'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

const Login: React.FC = (): ReactElement => {
  return (
    <div>
      <h2>ログイン</h2>
      <p>※ログインをすると<Link to="/privacy">プライバシーポリシー</Link>に同意したものとみなします。</p>
      <Button color="primary" variant="outlined" href="https://api.shimamuraft.net/auth/google">Googleアカウントででログイン</Button>
    </div>
  )
}

export default Login